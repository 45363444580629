import React from "react";
// import capImg from "./../assets/png/cap.png";
function SliderCard({ img, value }) {
  return (
    <>
      <div
        className="max-w-[375px] bg-secondary border md:border-[2px] lg:border-[4px] border-black rounded-2xl backdrop-blur-[20px]
      p-3 md:p-4 xl:p-6 w-[48%] md:w-[33%] lg:w-[24%] flex flex-col gap-y-2 md:gap-y-3 lg:gap-y-5 xl:gap-y-7"
      >
        <div
          className="border md:border-2 border-black rounded-lg w-full flex-grow  bg-primary
          flex flex-col justify-end items-end"
        >
          <img src={img} alt="img" className="w-full h-auto" />
        </div>
        <p className="text-white text-sm sm:text-base   xl:text-lg   font-gliker-semi-bold tracking-[0.4]  pb-2 ">
          {value}
        </p>
      </div>
    </>
  );
}

export default SliderCard;
